import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import "./style.styl";


export default function MuiSelect(props) {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const {items, selected, variant, helperText, error, margin, label, htmlFor, onOpen, onSelect, className, disabled} = props;
    return (
        <FormControl variant={variant || "outlined"} margin={margin || "dense"} className={className || ""} error={!!error} disabled={disabled}>
            <InputLabel ref={inputLabel} htmlFor={htmlFor}>
              {label}
            </InputLabel>
            <Select
              value={selected}
              labelWidth={labelWidth}
              onOpen={() => onOpen ? onOpen() : null}
              onChange={(event, item) => onSelect(event.target.value, item.props.item)}
              inputProps={{id: htmlFor}}
            >
                {
                    items.map((item, i) => {
                        return <MenuItem disabled={item.disabled} key={i} value={item.value} item={item}>{item.name}</MenuItem>;
                    })
                }
            </Select>
            {
                helperText ?
                    <FormHelperText error={!!error}>{helperText}</FormHelperText>
                    :
                    null
            }

        </FormControl>
    );
}
